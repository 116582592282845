<template>
  <div class="q-pa-lg container">
    <div class="row">
      <div v-if="$q.platform.is.desktop" class="col-12">
        <q-img
          alt="Dashboard CRDC"
          :src="bannerDashboard"
          contain
          @click="openCrdcUrl"
        />
      </div>
      <div class="col-12 q-mt-md">
        <q-expansion-item
          expand-separator
          :label="t('LABEL.FAST_PASS')"
          expand-icon-toggle
          default-opened
          style="font-size: 20px; color: #202121; font-weight: 500"
          class="expansion-item-fast-pass"
        >
          <div class="row q-col-gutter-lg q-mt-sm">
            <div
              v-if="
                profile &&
                isAllowed(
                  ['documentManagement:send-doc-documents'],
                  'documentManagement'
                )
              "
              class="col-lg-3 col-md-6 col-sm-6 col-xs-12"
            >
              <shortcut-card
                :title="t('DASHBOARD.FAST_ACCESS.CREATE_NEW_ENVELOPE')"
                :sub-title="t('DASHBOARD.FAST_ACCESS.MODULE_MANAGE_ENVELOPE')"
                color="#ED6C02"
                @click="goToNewEnvelope"
              />
            </div>
            <div
              v-if="
                profile &&
                isAllowed(
                  ['documentManagement:page-documents'],
                  'documentManagement'
                )
              "
              class="col-lg-3 col-md-6 col-sm-6 col-xs-12"
            >
              <shortcut-card
                :title="t('DASHBOARD.FAST_ACCESS.MANAGE_ENVELOPE')"
                :sub-title="t('DASHBOARD.FAST_ACCESS.MODULE_MANAGE_ENVELOPE')"
                color="#ED6C02"
                @click="goToManageEnvelopes"
              />
            </div>
            <div
              v-if="
                profile &&
                isAllowed(
                  ['accessManagement:create-contractor'],
                  'accessManagement'
                )
              "
              class="col-lg-3 col-md-6 col-sm-6 col-xs-12"
            >
              <shortcut-card
                :title="t('LABEL.CONTRACTOR_ADD')"
                :sub-title="t('LABEL.CONTRACTOR_ADD_MODULE')"
                color="#AB47BC"
                @click="goToContractors()"
              />
            </div>
            <div
              v-if="
                profile &&
                isAllowed(
                  ['accessManagement:user-group-contractor'],
                  'accessManagement'
                )
              "
              class="col-lg-3 col-md-6 col-sm-6 col-xs-12"
            >
              <shortcut-card
                :title="t('FORM.ACTION.USER_GROUPS')"
                :sub-title="t('LABEL.USER_GROUPS_MODULE')"
                color="#80CBC4"
                @click="goToUsers()"
              />
            </div>
            <!-- <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
              <shortcut-card />
              <q-tooltip class="text-body2">
                {{ t('FORM.ACTION.UNAVAILABLE_ACTION') }}
              </q-tooltip>
            </div> -->
          </div>
        </q-expansion-item>
      </div>
    </div>
    <!-- <div class="col-12 q-mt-md">
      <q-expansion-item
        expand-separator
        :label="t('LABEL.TOTALIZERS')"
        expand-icon-toggle
        default-opened
        style="font-size: 20px; color: #202121; font-weight: 500"
      >
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 q-mt-lg">
            <div class="row q-col-gutter-lg">
              <div class="col-lg-6 col-xs-12">
                <widget-card />
                <q-tooltip class="text-body2">
                  {{ t('FORM.ACTION.UNAVAILABLE_ACTION') }}
                </q-tooltip>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 q-mt-lg q-pl-md">
            <line-chart />
          </div>
        </div>
      </q-expansion-item>
    </div>
    <div class="col-12 q-mt-md">
      <q-expansion-item
        expand-separator
        :label="t('LABEL.RECENT_RECORDS')"
        expand-icon-toggle
        default-opened
        style="font-size: 20px; color: #202121; font-weight: 500"
      >
        <q-table
          v-model:selected="selected"
          :title="t('LABEL.DOCUMENT', 2)"
          :rows="rows"
          :columns="columns"
          row-key="name"
          selection="multiple"
          class="q-mt-md"
          :style="{
            boxShadow: 'none !important',
            color: '#666666',
          }"
          :no-data-label="t('TABLE.NO_DATA')"
        >
          <template #top>
            <p class="title-table">
              <q-icon
                size="1.5em"
                name="o_article"
                color="#747474"
                class="q-mr-sm"
              />{{ t('LABEL.DOCUMENT', 2) }}
            </p>
            <q-space />
            <q-select
              v-model="visibleColumns"
              :options="columns"
              color="#005090"
              borderless
              multiple
              dense
              emit-value
              map-options
              option-value="name"
              display-value="COLUNAS"
              hide-dropdown-icon
              class="select-columns"
            >
              <template #prepend>
                <q-icon style="color: #005090" name="filter_list" />
              </template>
            </q-select>
            <div class="col-12">
              <hr :style="{ border: '0.1px solid #E0E0E0' }" />
            </div>
          </template>
          <template #body-cell-actions="{ row }">
            <q-td :row="row" :style="{ textAlign: 'right' }">
              <q-icon
                small
                name="history_edu"
                class="mr-2 cursor-pointer"
                size="1.5em"
                :style="{ marginRight: '10px', color: '#757575' }"
              />
              <q-icon
                small
                name="more_vert"
                class="mr-2 cursor-pointer"
                size="1.5em"
                :style="{ color: '#757575' }"
              />
            </q-td>
          </template>
          <template #body-cell-progress="{ row }">
            <q-td
              :row="row"
              :style="{
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                marginTop: '7px',
              }"
            >
              <q-linear-progress
                size="12px"
                :value="progress1"
                :style="{
                  backgroundColor: '#BAD6F2',
                  color: '#005090',
                  marginRight: '5px',
                }"
              >
              </q-linear-progress>
              <p
                :style="{
                  display: 'contents',
                  fontSize: '14px',
                  color: '#666666',
                }"
              >
                {{ row.progress }}
              </p>
            </q-td>
          </template>
          <template #no-data="{ message }">
            <div class="full-width row flex-center q-gutter-sm">
              <span>{{ message }}</span>
            </div>
          </template>
        </q-table>
      </q-expansion-item>
    </div> -->
  </div>
</template>

<script setup>
/* eslint-disable */
import bannerDashboard from '@/assets/banner_dashboard.svg'
import LineChart from '@/components/lineChart/LineChart'
import ShortcutCard from '@/components/shortcutCard/ShortcutCard.vue'
import WidgetCard from '@/components/widgetCard/WidgetCard.vue'
import { event, router, storage } from '@crdc/utils'
import { useQuasar } from 'quasar'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

const $router = useRouter()
const { t } = useI18n()
const $q = useQuasar()

const columns = [
  {
    name: 'responsible',
    required: true,
    label: 'Responsável',
    align: 'center',
    field: (row) => row.name,
    format: (val) => `${val}`,
    sortable: true,
  },
  {
    name: 'document',
    align: 'center',
    label: 'Documento',
    field: (row) => row.name,
    sortable: true,
  },
  {
    name: 'sent',
    align: 'center',
    label: 'Enviado em ',
    field: (row) => row.name,
    sortable: true,
  },
  { name: 'expire', label: 'Expira em', field: 'expire', align: 'center' },
  { name: 'progress', label: 'Progresso', field: 'progress', align: 'center' },
  { name: 'actions', label: 'Ações', field: 'actions', align: 'right' },
]

const visibleColumns = [
  'responsible',
  'document',
  'sent',
  'expire',
  'progress',
  'actions',
]

const rows = ref([])
const profile = ref(null)

// const iconSearch = ref(
//   '<i style="font-size: 20px; margin-left: 2px" class="q-icon notranslate material-icons" aria-hidden="true" role="presentation">search</i>'
// )

onMounted(() => {
  profile.value = storage.get('user-modules')
  event.listen('NAVBAR:STORES:PROFILE:UPDATE', () => {
    updateProfile()
  })
  /*** icone de Acesso rapido - voltar codigo quando funcionalidade for implementada ***/

  // setTimeout(() => {
  //   const expansionItems = document.querySelectorAll('.expansion-item-fast-pass')
  //   expansionItems.forEach((expansionItem) => {
  //     const label = expansionItem.querySelector('.q-item__label')
  //     const text = label.textContent
  //     const newLabel = text + this.iconSearch
  //     label.innerHTML = newLabel
  //   })
  // }, 500)
})

function openCrdcUrl() {
  window.open('https://www.crdc.com.br', '_blank')
}

function goToNewEnvelope() {
  $router.push({
    path: '/document-management/envelopes/new-envelope',
  })
}
function goToManageEnvelopes() {
  $router.push({
    path: '/document-management/envelopes',
  })
}
function goToContractors() {
  $router.push({
    path: '/access-management/contractor/create',
  })
}
function goToUsers() {
  $router.push({ path: '/access-management/people' })
}
function updateProfile() {
  profile.value = storage.get('user-modules')
}
function isAllowed(items, routeName) {
  return router.isAccessAllowed(items, routeName)
}
</script>

<style lang="scss" scoped>
.container {
  background: #f6fafd;
  height: calc(100vh - 68px);
}

.title-table {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 160%;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
  color: #005090;
  margin: 10px 0 10px 10px;
}
:deep(.select-columns) {
  span {
    font-weight: 500;
    color: #005090;
  }
}

:deep(.q-btn__content) {
  flex-flow: row-reverse;
  .q-icon {
    margin-right: 10px;
  }
}
:deep(.q-table__middle) {
  padding: 0 16px;
}
:deep(.q-table__top) {
  padding: 12px 12px 12px 0 !important;
}
:deep(.q-expansion-item__container .q-item) {
  padding: 8px 0px !important;
}
:deep(.q-table) {
  th {
    font-size: 0.875rem;
    font-weight: 500;
  }
  td {
    font-weight: 400;
    font-size: 0.875rem;
  }
}
:deep(.q-field__native) {
  font-weight: 500;
  color: #005090;
}
</style>
