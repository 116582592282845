export default [
  {
    name: 'DocumentsDocumentManagement',
    path: '/documents',
    children: [
      {
        name: 'DocumentsDocumentManagementSendDocument',
        path: '/send-document',
      },
      /*
      {
        name: "DocumentsDocumentManagementCreate",
        path: "create",
        component: () => import("@/pages/DocumentsManagement/Create.vue"),
        meta: { requiresAuth: true }
      },
      {
        name: "DocumentsDocumentManagementView",
        path: "view/:id",
        component: () => import("@/pages/DocumentsManagement/View.vue"),
        meta: { requiresAuth: true }
      },
      {
        name: "DocumentsDocumentManagementEdit",
        path: "edit/:id",
        component: () => import("@/pages/DocumentsManagement/Edit.vue"),
        meta: { requiresAuth: true }
      },
      */
    ],
  },
]
