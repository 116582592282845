import { i18n as messagesUtils } from '@crdc/utils'
import { boot } from 'quasar/wrappers'
import { createI18n } from 'vue-i18n'
import messagesDashBoard from './messages'

const i18n = createI18n({
  locale: 'pt-Br',
  legacy: false,
  globalInjection: true,
  fallbackLocale: 'pt-Br',
  numberFormats: {
    'pt-Br': {
      currency: {
        style: 'currency',
        currency: 'BRL',
      },
    },
  },
  messages: {
    'pt-Br': { ...messagesUtils['pt-Br'], ...messagesDashBoard },
  },
})

export default boot(({ app }) => {
  app.use(i18n)
})

export { i18n }
