export default [
  {
    path: '/contractor',
    children: [
      {
        path: '/create',
      },
    ],
  },
  {
    path: '/people',
  },
]
