import Router from '@/components/Router.vue'
import { createRouter, createWebHistory } from 'vue-router'
import accessManagement from './access-management'
import documents from './documents'

const routes = [
  {
    path: '/document-management',
    component: Router,
    children: [...documents],
  },
  {
    path: '/access-management',
    component: Router,
    children: [...accessManagement],
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
